<template>
  <ion-page>
    <!-- Header -->
    <ion-header id="dark-header">
      <ion-toolbar class="text-center">
        <ion-buttons slot="start">
          <ion-button @click.prevent="goBack()">
            <ion-icon :icon="chevronBack"></ion-icon>
          </ion-button>
        </ion-buttons>

        <ion-title>Edit Genre</ion-title>
      </ion-toolbar>
    </ion-header> 
    <!-- Page Content -->
    <ion-content>
      <!-- Home Feed -->
      <div v-if="isAdmin === true" class="app-max-width">
        <GenreEditForm :id="genreId" />
      </div>
      <div v-else class="app-max-width">
        Not authorized
      </div>
    </ion-content>

  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle } from '@ionic/vue';
import { chevronBack, add, reorderThree } from 'ionicons/icons';
import { defineComponent, ref, onMounted } from 'vue';
import doAuth from '../../services/auth';
import { useRouter } from 'vue-router';
import GenreEditForm from '../../components/GenreEditForm.vue';

export default defineComponent({
  name: 'AdminGenreEdit',
  components: {
    IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle, GenreEditForm
  },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const router = useRouter();
    const isAdmin = ref(true);
    const genreId = ref(0);

    onMounted(() => {
      genreId.value = router.currentRoute._rawValue.params.id
    })

    function updateRoute(val) {
      router.push(val)
    }

    function goBack() {
      router.go(-1)
    }

    return {
      chevronBack, add, router, updateRoute, goBack, isAdmin, reorderThree, genreId
    }
  }
});
</script>

<style scoped>

</style>